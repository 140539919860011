import React, {Suspense} from "react";
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/store"
import './App.css';
import "./assets/style/style.scss";
import Splash from "./views/main/Splash"
import Login from "./views/login/Login";
import MainA from "./views/template/A/main/Main";
import MainB from "./views/template/B/main/Main";
import MainC from "./views/template/C/main/Main";
import Frame from "./views/main/Frame";
import UserRegister from "./views/main/UserRegister";
import Login2 from "./views/login/Login2";
import Inspection from "./views/main/Inspection";

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Suspense>
                    <Routes>
                        <Route path={"/"} name={"Splash"} element={<Splash />} />
                        <Route path={"/main"} name={"Frame"} element={<Frame />} />
                        {/*<Route path={"/login"} name={"Login"} element={<Login />}/>*/}
                        <Route path={"/login"} name={"Login"} element={<Login2 />}/>
                        {/*<Route path={"/a/main"} name={"MainA"} element={<MainA />} />*/}
                        {/*<Route path={"/b/main"} name={"MainB"} element={<MainB />} />*/}
                        {/*<Route path={"/c/main"} name={"MainC"} element={<MainC />} />*/}
                        <Route path={"/register"} name={"Register"} element={<UserRegister />} />
                        <Route path={"/inspect"} name={"Inspection"} element={<Inspection />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </Provider>
    )
}

export default App