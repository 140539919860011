import React, {useEffect, useState} from "react";
import ic_close from "../../../../../assets/ic-close.svg";
import * as api from "../../../../../api/api";
import {connect, useSelector} from "react-redux";

const Withdraw = (props) => {
    const {user} = useSelector((props) => props.account);
    const defaultUser = {
        companyCode: "0000", companyType: "0000", userId: "", password: "", userType: "6000",
        parentId: "test", mobileNo: "", userName: "", nickName: "", amount: 0,
        status: 0, bankName: "", bankAccount: "", bankOwner: ""
    }
    const [requestWithdraw, setRequestWithdraw] = useState(defaultUser)

    useEffect(() => {

    })

    const onClose = () => {
        props.onClose();
    }

    const onWithdraw = () => {
        const withdraw = {
            userId: user.userId,
            bankName: requestWithdraw.bankName,
            bankAccount: requestWithdraw.bankAccount,
            bankOwner: requestWithdraw.bankOwner,
            amount: requestWithdraw.depositAmount,
            status: 0
        }

        if (window.confirm("출금신청 하시겠어요?")) {
            api.putWithdraw(withdraw).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("출금신청 등록이 완료되었습니다.")
                    props.onClose()
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    return (
        <div className={"bg-gray-800 py-[30px] rounded"}>
            <div className={"flex flex-row justify-end px-[36px]"}>
                <img src={ic_close} alt={""} onClick={onClose}/>
            </div>
            <p className={"text-white text-center text-4xl font-bold"}>{props.title}</p>

            <div className={"flex flex-col px-8 mt-8 gap-2"}>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            예금주
                        </span>
                    <input
                        id="bankOwner"
                        name="bankOwner"
                        type="text"
                        value={requestWithdraw.bankOwner}
                        onChange={event => {
                            setRequestWithdraw((state) => ({
                                ...state,
                                bankOwner: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            은행명
                        </span>
                    <input
                        id="bankName"
                        name="bankName"
                        type="text"
                        value={requestWithdraw.bankName}
                        onChange={event => {
                            setRequestWithdraw((state) => ({
                                ...state,
                                bankName: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            계좌번호
                        </span>
                    <input
                        id="bankAccount"
                        name="bankAccount"
                        type="text"
                        value={requestWithdraw.bankAccount}
                        onChange={event => {
                            setRequestWithdraw((state) => ({
                                ...state,
                                bankAccount: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            출금 신청 금액
                        </span>
                    <input
                        id="amount"
                        name="amount"
                        value={requestWithdraw.amount}
                        onChange={event => {
                            setRequestWithdraw((state) => ({
                                ...state,
                                amount: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-end text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <button className={"rounded mt-4 p-2 bg-blue-500 text-lg text-white font-bold"}
                        onClick={onWithdraw}>
                    출금신청
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

export default connect(mapStateToProps, null)(Withdraw);