import Header from "./header/Header";
import Contents from "./Contents";
import UserLogout from "./user/UserLogout";
import Left from "./Left";
import Right from "./Right";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import UserLogin from "./user/UserLogin";
import {accountActions} from "../../redux/actions/account";
import {connect, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as api from "../../api/api";
import PointExchange from "./modal/PointExchange";
import {CModal} from "@coreui/react";
import * as Global from "../biz/Global";
import {getSiteInspection} from "../../api/api";
import axios from "axios";

const Frame = (props) => {
    const {user} = useSelector((props) => props.account);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const [menuIdx, setMenuIdx] = useState(0)
    const [userInfo, setUserInfo] = useState({})
    const [pointExchangeOpen, setPointExchangeOpen] = useState(false)

    useEffect(() => {
        async function getUserIp() {
            // ip 정보 가져오기
            let res = await axios.get('https://api64.ipify.org/');
            if (res.status === 200) {
                props.setMyIp(res.data)
            }
        }
        getUserIp();

        // 점검 여부 체크
        getSiteInspect()


        if (user === undefined) {
            setIsLogin(false)
            setUserInfo({})
        }
        else {
            setIsLogin(true)
            setUserInfo(user)
        }
    }, [user])

    const setLogin = (value) => {
        setIsLogin(value)
    }

    const onMenu = (idx) => {
        if (user === undefined) {
            // 로그인 한 사용자가 아니면 로그인 페이지로 강제 이동
            navigate("/login")
        }

        if (idx === Global.MENU_EXCHANGE_POINT) {
            setPointExchangeOpen(true)
            return
        }

        setMenuIdx(idx)
        getUser()
    }

    const getSiteInspect = () => {
        const siteId = "game"

        api.getSiteInspection(siteId).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                // 점검 상태 체크
                if (Object.keys(data).length === 0) {
                    // 정상
                    return
                }
                else {
                    if (data.status === 1) {
                        navigate("/inspect", {state: data})
                    }
                }
            }
        })
    }

    const getUser = () => {
        if (user !== undefined && Object.keys(user).length > 0) {
            const {userId, password} = user;

            const params = {
                userId: userId,
                password: password
            }

            setLoading(true)
            api.login(params).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    // 사용자 정보 확인
                    if (Object.keys(data).length > 0) {
                        // 로그인 타입 확인
                        props.setAccount(data)
                        setLogin(true)
                    }
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    return (
        <div className={"flex-1 flex-col bg-black"}>
            <Header onMenu={onMenu} />
            {isLogin &&
            <UserLogin setLogin={setLogin} onMenu={onMenu} />
            }
            {!isLogin &&
            <UserLogout setLogin={setLogin} onMenu={onMenu} />
            }
            <div className={"flex flex-row justify-center"}>
                <Left />
                <Contents menuIdx={menuIdx} onMenu={onMenu} />
                <Right />
            </div>
            <Footer />

            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={pointExchangeOpen}>
                <PointExchange title={"포인트 전환"}
                               content={"포인트 전환"}
                               onClose={() => {
                                   setPointExchangeOpen(false);
                               }} />
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        setMyIp: (ip) => {
            dispatch(accountActions.setMyIp(ip))
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Frame)