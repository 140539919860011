import {useEffect, useState} from "react";
import Loading from "../../component/Loading";
import * as api from "../../api/api";
import {matches} from "../../assets/data/match";

const FreeMatch = ({props, onCart}) => {
    const [loading, setLoading] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(matches[0])
    const [contentsList, setContentsList] = useState([])
    const [oddsList, setOddsList] = useState([])
    const [selectMatchId, setSelectMatchId] = useState(0)
    const [selectOdds, setSelectOdds] = useState([])
    const [bettingList, setBettingList] = useState([])

    useEffect(() => {
        setSelectOdds([]);
        getMatchList(props.id);
    }, [props])

    const getMatchList = (match) => {
        setLoading(true);
        api.getMatchList(match).then(res => {
            const {data, status, statusText} = res;
            if (status === 200) {
                const {success, result} = res.data
                if (success === 1) {
                    // 성공
                    if (result !== null) {
                        setContentsList(result.filter(item => item.id !== 0 && item.status_id === 0));
                    } else {
                        setContentsList([]);
                    }
                }
                else {
                    // API 통신 실패
                    window.alert("API 서버 통신 실패");
                }
            }
            else {
                // API 통신 에러
                window.alert("API 서버 통신 에러");
            }
        })
            .then(api.getOddsList(match).then(res2 => {
                const {data, status, statusText} = res2;
                if (status === 200) {
                    const {success, result} = res2.data
                    if (success === 1) {
                        // 성공
                        if (result !== null) {
                            setOddsList(result.filter(item => item.id !== 0));
                        } else {
                            setOddsList([]);
                        }
                    }
                    else {
                        // API 통신 실패
                        window.alert("API 서버 통신 실패");
                    }
                }
            }))
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onSelectMatch = (match) => {
        // 선택한 경기 정보를 담자
        setSelectedMatch(match);
        // 선택한 경기의 배당 정보 가져오기
        const filterOddsList = oddsList.filter(item => item.id === match.id);
        if (filterOddsList.length > 0) {
            // 승무패, 핸디캡, 언더오버가 Default
            // 축구 승무패 10001, 핸디캡 10008, 다기준 언더오버는 없네
            // 미식축구 승패 11001 핸디캡 11002 언더오버 11003
            // 복싱 12001
            // 테니스 승패 13001 핸디캡 13002 언더오버 13007
            // 야구 승패 14001 핸디캡 14002 언더오버 14003
            // 아이스하키 승무패 15004 핸디캡 15002 언더오버 15003
            // 농구 승패 16001 핸디캡 16002 언더오버 16003
            // 핸드볼 승무패 17001 핸디캡 17002 언더오버 17003
            // 배구 18001 18002 18003
            // 탁구 19001
            // e스포츠 20001 20002 20003
            // 종목별로 승무패, 핸디캡, 언더오버만 가져와야함
            const marketList = filterOddsList[0].market
            let lastFilterList = []
            if (match.sport === 'soccer') {
                lastFilterList = marketList.filter(item => item.market_id === 10001 || item.market_id === 10008)
            }
            else if (match.sport === 'esports') {
                lastFilterList = marketList.filter(item => item.market_id === 20001 || item.market_id === 20002 || item.market_id === 20003)
            }
            else if (match.sport === 'baseball') {
                lastFilterList = marketList.filter(item => item.market_id === 14001 || item.market_id === 14002 || item.market_id === 14003)
            }
            else if (match.sport === 'basketball') {
                lastFilterList = marketList.filter(item => item.market_id === 16001 || item.market_id === 16002 || item.market_id === 16003)
            }
            else if (match.sport === 'tabletennis') {
                lastFilterList = marketList.filter(item => item.market_id === 19001)
            }

            setSelectOdds(lastFilterList)
        }
    }

    const onBettingCart = (item, odd) => {
        // 장바구니용 데이터 만들기
        const cart = {
            id: selectedMatch.id,
            league_name: selectedMatch.league_name,
            sport: selectedMatch.sport,
            market_id: item.market_id,
            market_name: item.market_name,
            odd_name: odd.name,
            odd_value: odd.value
        }
        onCart(cart)
    }

    return (
        <div className={"flex flex-row h-screen"}>
            <div className={"flex flex-col py-4"}>
                {loading ? <Loading /> : null}
                {contentsList.length > 0 &&
                <div className={"flex flex-col overflow-y-auto h-5/6"}>
                    {contentsList.map((item, idx) => {
                        return (
                            <div key={String(idx)} className={"flex flex-col hover:border-blue-500 hover:border-2"} onClick={() => onSelectMatch(item)}>
                                <div className={"flex flex-row my-2 justify-between items-center"}>
                                    <div className={"flex flex-row gap-2 items-center"}>
                                        <img className={"w-8 h-8"} src={item.cc_image} alt={""}/>
                                        <span className={"text-white text-sm font-bold"}>[{item.cc_kr}]</span>
                                        <span className={"text-white text-sm font-bold"}>{item.league_name}</span>
                                    </div>
                                    <span className={"text-gray-300 text-sm"}>{item.time}</span>
                                </div>
                                <div className={"flex flex-row rounded px-2 items-center py-2 gap-2 bg-[#181d26]"}>
                                    {/* 경기 상태 */}
                                    <div className={"w-[320px] flex flex-row"}>
                                        <span className={"w-[200px] text-white text-sm"}>{item.time}</span>
                                        {item.status_id === 0 &&
                                        <span className={"w-[120px] text-center text-white text-sm"}>{item.status_kr}</span>
                                        }
                                        {item.status_id === 1 &&
                                        <span className={"w-[120px] text-center bg-red-500 text-white text-sm"}>
                                        {item.timer ? item.timer : item.status_kr} '
                                    </span>
                                        }
                                        {item.status_id === 2 &&
                                        <span className={"w-[120px] text-center text-white text-sm"}>{item.status_kr}</span>
                                        }
                                    </div>
                                    {/* 팀 정보 */}
                                    <div className={"flex flex-1 flex-row justify-between"}>
                                        <div className={"flex flex-row gap-2"}>
                                            <div className={"flex flex-row gap-1 items-center py-1"}>
                                                {item.home_image ? <img className={"w-4 h-4"} src={item.home_image} alt={""}/> : null}
                                                <span className={"text-white text-sm"}>{item.home_name}</span>
                                            </div>
                                            <span className={"text-gray-500"}>vs</span>
                                            <div className={"flex flex-row gap-1 items-center py-1"}>
                                                {item.away_image ? <img className={"w-4 h-4"} src={item.away_image} alt={""}/> : null}
                                                <span className={"text-white text-sm"}>{item.away_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(selectOdds.length > 0 && item.id === selectedMatch.id) &&
                                <div className={"flex flex-col px-2 py-4"}>
                                    <div className={"flex flex-col gap-1"}>
                                        {selectOdds.map((item, idx) => {
                                            return (
                                                <div key={String(idx)} className={"flex flex-col p-2 rounded bg-[#181d26]"}>
                                                    <span
                                                        className={"w-[80px] items-center px-2 py-1 rounded bg-blue-500 text-white text-sm font-bold"}>
                                                        {item.market_name}
                                                    </span>
                                                    {item.list.length === 1 &&
                                                    <div className={"flex flex-col"}>
                                                        <div className={"flex flex-row px-2 items-center justify-between"}>
                                                            <span className={"w-[100px] text-center text-sm text-white font-bold"}>Home</span>
                                                            <span className={"w-[100px] text-center text-sm text-white font-bold"}>Draw</span>
                                                            <span className={"w-[100px] text-center text-sm text-white font-bold"}>Away</span>
                                                        </div>
                                                        <div
                                                            className={"flex flex-row px-2 items-center justify-between"}>
                                                            {item.list[0].odds.map((odd, idx) => {
                                                                return <span key={String(idx)}
                                                                             className={"cursor-pointer w-[100px] text-center text-sm text-white font-bold hover:bg-[#16bbf7]"}
                                                                             onClick={() => onBettingCart(item, odd)}>{odd.value}</span>
                                                            })}
                                                        </div>
                                                    </div>
                                                    }
                                                    {item.list.length > 1 &&
                                                    <div className={"flex flex-col"}>
                                                        {item.list.map((row, idx) => {
                                                            return (
                                                                <div key={String(idx)}>
                                                                    {row.stop === 0 &&
                                                                    <div>
                                                                        <div className={"flex flex-col"}>
                                                                            <div className={"flex flex-row px-2 items-center justify-between"}>
                                                                                <span className={"w-[100px] text-center text-sm text-white font-bold"}/>
                                                                                {item.list[idx].odds.map((odd, idx) => {
                                                                                    return <span key={String(idx)}
                                                                                                 className={"w-[100px] text-center text-sm text-white font-bold"}>{odd.name}
                                                                                        {odd.stop === 1 ? <span
                                                                                            className={"ml-1 px-1 rounded bg-red-500 text-white text-sm"}>정지</span> : null}</span>
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        <div className={"flex flex-row items-center justify-between"}>
                                                    <span className={"w-[100px] text-center text-sm text-[#16bbf7]021410 font-bold"}>
                                                        {row.name}{row.stop === 1 ? <span
                                                        className={"ml-1 px-1 rounded bg-red-500 text-white text-sm"}>정지</span> : null}
                                                    </span>
                                                                            {item.list[idx].odds.map((odd, idx) => {
                                                                                return <span key={String(idx)}
                                                                                             className={"cursor-pointer w-[100px] text-center text-sm text-white font-bold hover:bg-[#16bbf7]"}
                                                                                             onClick={() => onBettingCart(item, odd)}>{odd.value}</span>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                }
                            </div>
                        )
                    })}
                </div>
                }
                {contentsList.length === 0 &&
                <div className={"bg-yellow-100 p-4 rounded drop-shadow"}>
                    <span className={"text-yellow-900 font-bold"}>경기가 없습니다.</span>
                </div>
                }
            </div>
        </div>
    )
}

export default FreeMatch;