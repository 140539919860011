import {useEffect, useState} from "react";
import {TrashIcon} from "@heroicons/react/24/outline";
import numeral from "numeral";
import BettingRow from "./BettingRow";
import {calcOdds} from "../biz/Betting";
import {accountActions} from "../../redux/actions/account";
import {connect, useSelector} from "react-redux";

const Right = (props) => {
    const {user} = useSelector((props) => props.account);
    const [bettingInfo, setBettingInfo] = useState({
        minimumBet: 5000, maximumBet: 10000000, maximumWinning: 999999999, expectWinning: 0
    })
    const [bettingPrice, setBettingPrice] = useState(0)
    const [oddPer, setOddPer] = useState(1)

    useEffect(() => {
        // 배팅정보를 기반으로 계산
        const bettingList = props.bettingList;
        if (bettingList.length > 0) {
            // 배당율 계산
            const oddsPer = calcOdds(bettingList)
            setOddPer(oddsPer)
        }
        else {
            setOddPer(1)
        }
        calc()
    }, [props])

    useEffect(() => {
        calc()
    }, [bettingPrice])

    const init = () => {
        setBettingPrice(0)
        props.init()
    }

    const bettingAmount = (amount) => {
        const {info} = props;
        let totalAmount = bettingPrice + amount;
        if (totalAmount > user.userPoint) {
            // 내가 가지고 있는 금액 이상은 안됨
            totalAmount = user.userPoint
        }
        setBettingPrice(totalAmount)
        props.onBettingAmount(totalAmount)
        calc()
    }

    const clear = () => {
        setBettingPrice(0)
    }

    const calc = () => {
        // 예상 당첨금액을 구해주자
        let expectAmount = Math.round(bettingPrice * oddPer * 100) / 100;
        // 예상 금액이 최대 금액을 넘으면 최대 금액으로
        if (expectAmount > bettingInfo.maximumWinning) {
            expectAmount = bettingInfo.maximumWinning
        }
        setBettingInfo({
            ...bettingInfo,
            expectWinning: expectAmount
        })
    }

    const onBetting = () => {
        props.onBetting();
    }

    const onDelete = (idx) => {
        props.onBettingRowDelete(idx)
    }

    return (
        <div className={"min-w-[300px] max-w-[300px] flex flex-col p-4 bg-[#0b0e18]"}>
            <div className={"flex flex-row p-2 justify-between bg-[#222a33]"}>
                <div className={"flex flex-row gap-2"}>
                    <span className={"text-white text-xs"}>전체</span>
                    <span className={"text-[#16bbf7] text-xs font-bold"}>{props.bettingList.length}</span>
                </div>
                <button className={"rounded text-xs font-bold text-white"} onClick={init}>
                    <TrashIcon className="h-4 w-4 text-white" />
                </button>
            </div>
            <div className={"h-[300px] flex flex-col py-2 mb-2 items-center overflow-y-auto"}>
            {props.bettingList.length > 0 &&
                <div className={"flex flex-col w-full gap-2"}>
                    {props.bettingList.map((item, idx) => {
                        return (
                            <BettingRow key={String(idx)} idx={idx} item={item} onDelete={onDelete} />
                        )
                    })}
                </div>
            }
            </div>
            <div className={"flex flex-col bg-[#2a3340] p-1"}>
                <div className={"flex flex-col mb-1"}>
                    <div className={"flex flex-row p-2 bg-[#222a33] items-center justify-between"}>
                        <span className={"text-white text-xs"}>배당률</span>
                        <span className={"text-[#16bbf7] text-xs font-bold"}>x {oddPer}</span>
                    </div>
                    <div className={"flex flex-row p-2 bg-[#222a33] items-center justify-between"}>
                        <span className={"text-white text-xs"}>최소배팅금액</span>
                        <span className={"text-[#16bbf7] text-xs font-bold"}>{numeral(bettingInfo.minimumBet).format("0,0")} 원</span>
                    </div>
                    <div className={"flex flex-row p-2 bg-[#222a33] items-center justify-between"}>
                        <span className={"text-white text-xs"}>최대배팅금액</span>
                        <span className={"text-[#16bbf7] text-xs font-bold"}>{numeral(bettingInfo.maximumBet).format("0,0")} 원</span>
                    </div>
                    <div className={"flex flex-row p-2 bg-[#222a33] items-center justify-between"}>
                        <span className={"text-white text-xs"}>최대당첨금액</span>
                        <span className={"text-[#16bbf7] text-xs font-bold"}>{numeral(bettingInfo.maximumWinning).format("0,0")} 원</span>
                    </div>
                    <div className={"flex flex-row p-2 bg-[#222a33] items-center justify-between"}>
                        <span className={"text-white text-xs"}>예상당첨금액</span>
                        <span className={"text-[#16bbf7] text-xs font-bold"}>{numeral(bettingInfo.expectWinning).format("0,0")} 원</span>
                    </div>
                </div>
                <div className={"flex flex-row p-2 items-center bg-[#222a33] justify-between border border-[#16bbf7]"}>
                    <div className={"flex flex-row"}>
                        <span className={"text-xs text-white"}>배팅금액</span>
                    </div>
                    <input className={"w-36 px-2 text-end bg-[#222a33] text-[#16bbf7] text-xs font-bold"}
                           onChange={(e) => {
                               setBettingPrice(e.target.value)
                           }}
                           value={numeral(bettingPrice).format("0,0")}/>
                </div>
                <div className={"flex flex-col gap-0.5"}>
                    <div className={"flex flex-row gap-0.5"}>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={() => bettingAmount(5000)}>5,000</button>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={() => bettingAmount(10000)}>10,000</button>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={() => bettingAmount(50000)}>50,000</button>
                    </div>
                    <div className={"flex flex-row gap-0.5"}>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={() => bettingAmount(100000)}>100,000</button>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={() => bettingAmount(300000)}>300,000</button>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={() => bettingAmount(500000)}>500,000</button>
                    </div>
                    <div className={"flex flex-row gap-0.5"}>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={clear}>초기화</button>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={() => bettingAmount(99999999)}>최대금액</button>
                        <button className={"flex-1 py-2 text-white text-xs bg-[#222a33] hover:bg-blue-500"} onClick={() => bettingAmount(5000)}>최소금액</button>
                    </div>
                </div>
                <button className={"py-2 bg-blue-500 rounded-sm text-white text-sm font-bold"} onClick={onBetting}>
                    배팅하기
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(Right)