import {useState} from "react";
import numeral from "numeral";

const Right = (props) => {
    const [betting, setBetting] = useState({
        bettingAmount: 0
    });

    const init = () => {
        setBetting((state) => ({
            ...state, bettingAmount: 0
        }))
    }

    const bettingAmount = (amount) => {
        setBetting((state) => ({
            ...state, bettingAmount: amount
        }))
    }

    return (
        <div className={"w-[320px] flex flex-col gap-4 p-4 bg-[#16181A]"}>
            <div className={"flex h-[120px] rounded-xl border-2 items-center justify-center border-[#444444] bg-[#222222]"}>
                <span className={"text-white text-xs"}>선택된 배팅내역이 없습니다.</span>
            </div>

            <div className={"flex flex-row items-center justify-between"}>
                <div className={"flex flex-row"}>
                    <span className={"rounded-l bg-[#222222] p-2 text-md text-[#e15d09] font-bold"}>배팅</span>
                    <button className={"rounded-r bg-[#333333] px-6 py-2 text-md text-white font-bold"}>
                        C
                    </button>
                </div>
                <input className={"w-36 px-2 py-2 text-center rounded border border-[#738087] bg-[#181c1c] text-white text-lg items-end font-bold"}
                       value={numeral(betting.bettingAmount).format("0,0")}/>
            </div>

            <div className={"flex flex-row gap-1.5"}>
                <button className={"rounded px-3 py-2 text-white text-sm bg-[#333333]"} onClick={() => bettingAmount(5000)}>오천</button>
                <button className={"rounded px-3 py-2 text-white text-sm bg-[#333333]"} onClick={() => bettingAmount(10000)}>일만</button>
                <button className={"rounded px-3 py-2 text-white text-sm bg-[#333333]"} onClick={() => bettingAmount(50000)}>오만</button>
                <button className={"rounded px-3 py-2 text-white text-sm bg-[#333333]"} onClick={() => bettingAmount(100000)}>십만</button>
                <button className={"rounded px-3 py-2 text-white text-sm bg-[#333333]"} onClick={() => bettingAmount(99999999)}>전액</button>
            </div>

            <div className={"flex flex-col p-2 gap-4 bg-[#0f1216]"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <span className={"text-white text-xs"}>배당률</span>
                    <span className={"text-white text-xs"}>1배</span>
                </div>
                <div className={"flex flex-row items-center justify-between"}>
                    <span className={"text-white text-xs"}>최소배팅금액</span>
                    <span className={"text-white text-xs"}>0원</span>
                </div>
                <div className={"flex flex-row items-center justify-between"}>
                    <span className={"text-white text-xs"}>최대배팅금액</span>
                    <span className={"text-white text-xs"}>0원</span>
                </div>
                <div className={"flex flex-row items-center justify-between"}>
                    <span className={"text-white text-xs"}>최대당첨금액</span>
                    <span className={"text-white text-xs"}>0원</span>
                </div>
                <div className={"flex flex-row items-center justify-between"}>
                    <span className={"text-white text-xs"}>예상당첨금액</span>
                    <span className={"text-white text-xs"}>0원</span>
                </div>
            </div>
            <button className={"mt-2 py-2 bg-[#E15D09] rounded text-white text-sm font-bold"}>
                배팅하기
            </button>
        </div>
    )
}

export default Right