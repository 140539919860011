import React, {useEffect, useState} from "react";
import ic_close from "../../../../../assets/ic-close.svg";
import * as api from "../../../../../api/api";
import {connect, useSelector} from "react-redux";

const Deposit = (props) => {
    const {user} = useSelector((props) => props.account);
    const defaultUser = {
        companyCode: "0000", companyType: "0000", userId: "", password: "", userType: "6000",
        parentId: "test", mobileNo: "", userName: "", nickName: "", bankAccount: "", depositAmount: 0,
        status: 9
    }
    const [depositList, setDepositList] = useState([])
    const [requestDeposit, setRequestDeposit] = useState(defaultUser)

    const onClose = () => {
        props.onClose();
    }

    const onRequestDeposit = () => {
        const deposit = {
            userId: user.userId,
            userName: requestDeposit.userName,
            amount: requestDeposit.depositAmount,
            status: 0
        }

        if (window.confirm("입금신청 하시겠어요?")) {
            api.putDeposit(deposit).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("등록이 완료되었습니다.")
                    props.onClose()
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    return (
        <div className={"bg-gray-800 py-[30px] rounded"}>
            <div className={"flex flex-row justify-end px-[36px]"}>
                <img src={ic_close} alt={""} onClick={onClose}/>
            </div>
            <p className={"text-white text-center text-4xl font-bold"}>{props.title}</p>

            <div className={"flex flex-col px-8 mt-8 gap-2"}>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            입금자명
                        </span>
                    <input
                        id="userName"
                        name="userName"
                        type="text"
                        value={requestDeposit.userName}
                        onChange={event => {
                            setRequestDeposit((state) => ({
                                ...state,
                                userName: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            입금신청 금액
                        </span>
                    <input
                        id="depositAmount"
                        name="depositAmount"
                        value={requestDeposit.depositAmount}
                        onChange={event => {
                            setRequestDeposit((state) => ({
                                ...state,
                                depositAmount: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-end text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <button className={"rounded mt-4 p-2 bg-blue-500 text-lg text-white font-bold"}
                        onClick={onRequestDeposit}>
                    입금신청
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

export default connect(mapStateToProps, null)(Deposit);